export const USER = {
  LOGIN: 'LOGIN'
};

export const AUTH = {
  LOGIN: 'LOGIN',
  SET_IS_LOGIN: 'SET_IS_LOGIN',
  REGISTER: 'REGISTER',
  SET_REGISTER_DATA: 'SET_REGISTER_DATA',
  ZIP_CODE: 'ZIP_CODE',
  CONFIRM_REGISTER: 'CONFIRM_REGISTER',
  LOGOUT: 'LOGOUT',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  USER_INFO: 'USER_INFO'
};

export const SIDEBAR = {
  CHANGE_SIDEBAR: 'CHANGE_SIDEBAR'
}

export const SET_LOCALE = "SET_LOCALE";