export const state = {
    pagePagination: 1, // Default to page 1
  };
  
export const mutations = {
    SET_PAGE_PAGINATION(state, page) {
    state.pagePagination = page;
    },
};

export const getters = {
    pagePagination: (state) => state.pagePagination,
};