import {
    addInterviewSchedule,
    deleteInterviewSchedule,
    getInterviewSchedule,
    updateInterviewSchedule
} from "@/apis/interview";

export const state = {
    interviewLists: {},
    updateData: {},
    addData: {},
    deleteData: {}
};

export const getters = {
    interviewLists: (state) => state.interviewLists,
    updateData: (state) => state.updateData,
    addData: (state) => state.addData,
    deleteData: (state) => state.deleteData,
};

export const mutations = {
    GETINTERVIEWSCHEDULE(state, data) {
        state.interviewLists = data
    },

    UPDATEINTERVIEWSCHEDULE(state, data) {
        state.updateData = data
    },

    ADDINTERVIEWSCHEDULE(state, data) {
        state.addData = data
    },

    DELETEINTERVIEWSCHEDULE(state, data) {
        state.deleteData = data
    }
};

export const actions = {
    async getInterviewSchedule({ commit }, params) {
        const res = await getInterviewSchedule(params);

        if (res.error) {
            return false;
        }
        commit("GETINTERVIEWSCHEDULE", res);
        return true;
    },

    async updateInterviewSchedule({ commit }, payload) {
        const res = await updateInterviewSchedule(payload);
        if (res.error) {
            return false;
        }
        commit("UPDATEINTERVIEWSCHEDULE", res);
        return true;
    },

    async addInterviewSchedule({ commit }, payload) {
        const res = await addInterviewSchedule(payload);
        if (res.error) {
            return false;
        }
        commit("ADDINTERVIEWSCHEDULE", res);
        return true;
    },

    async deleteInterviewSchedule({ commit }, payload) {
        const res = await deleteInterviewSchedule(payload);
        if (res.error) {
            return false;
        }
        commit("DELETEINTERVIEWSCHEDULE", res);
        return true;
    }
};
