import axios from '@/plugins/axios';

const JOBS = 'job';
const OVERVIEWJOB = '/job/overview-job';
const EDIT_JOB = '/job/edit-job';
const DELETE_JOB = '/job/delete-job';

export async function addJob(payload) {
  try {
    const { data } = await axios.post(`${JOBS}`, payload);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function deleteJob(payload) {
  try {
    const { data } = await axios.post(`${DELETE_JOB}/${payload}`,);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function editJob(payload) {
  try {
    const { data } = await axios.put(`${EDIT_JOB}/${payload.params.id}`, payload.body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function overviewJob(payload) {
  try {
    const { data } = await axios.get(`${OVERVIEWJOB}/${payload.params.id}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getEditJob(payload) {
  try {
    const { data } = await axios.get(`${EDIT_JOB}/${payload}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function updateJob(payload) {
  try {
    const { data } = await axios.post(`${JOBS}/${payload.id}`, payload);

    return data;
  } catch (error) {
    return { error };
  }
}