import { listCandidates, getCandidate, listRHR, updateStatus, addCandidate, deleteCandidate, addCalendar, addComment, updateOnBoard, assignOwn, updateCandidate, getCommunicationsBySource, 
  addApproachHistory, getCandidateAproachHistory, getEditHistories, 
  getEditCandidate,
  sharePublic,
  authSharePublic,
  checkAuthPublic,
  getCandidatePublic,
  getProcessCandidate,
  getStatus
} from "@/apis/candidate";
import { setPublicToken } from "@/utils/token";
import { notification } from "ant-design-vue";

export const state = {
  list_candidates: {},
  candidate_detail: {},
  list_rhr: {},
  response_post: {},
  response_process: {},
  approach_history: {},
  candidate_data_structure: {},
  communications: [],
  edit_histories: [],
  candidate_edit: {},
  share_public: {},
  auth_public_data: {},
  check_auth_public: {}
};

export const getters = {
  list_candidates: (state) => state.list_candidates,
  candidate_detail: (state) => state.candidate_detail,
  list_rhr: (state) => state.list_rhr,
  response_post: (state) => state.response_post,
  response_process: (state) => state.response_process,
  approach_history: (state) => state.approach_history,
  communications: (state) => state.communications,
  edit_histories: (state) => state.edit_histories,
  candidate_edit: (state) => state.candidate_edit,
  share_public: (state) => state.share_public,
  auth_public_data: (state) => state.auth_public_data,
  check_auth_public: (state) => state.check_auth_public,
  candidate_data_structure: (state) => state.candidate_data_structure,
};

export const mutations = {
  LISTCANDIDATES(state, data) {
    state.list_candidates = data
  },
  GETCANDIDATE(state, data) {
    state.candidate_detail = data
  },
  GETDATASTRUCTURE(state, data) {
    state.candidate_data_structure = data
  },
  LISTRHR(state, data) {
    state.list_rhr = data
  },
  RESPONSE(state, data) {
    state.response_post = data
  },
  RESPONSE_PROCESS(state, data) {
    state.response_process = data
  },
  APPROACH_HISTORY(state, data) {
    state.approach_history = data
  },
  COMMUNICATIONS(state, data){
    state.communications = data.map(item => {
      return{ 
        ...item,
        id: item.communication_id,
        name: item.communication_name,
      }
    })
  },
  EDIT_HISTORIES(state, data) {
    state.edit_histories = data
  },
  GETEDITCANDIDATE(state, data) {
    state.candidate_edit = data
  },
  SHARE_PUBLIC(state, data) {
    state.share_public = data
  },
  AUTH_SHARE_PUBLIC(state, data) {
    state.auth_public_data = data;
    setPublicToken(data.token);
  },
  CHECK_AUTH_PUBLIC(state, data) {
    state.check_auth_public = data;
  },
};

export const actions = {
  async listCandidates({ commit }, params) {
    const res = await listCandidates(params);
    if (res.error) {
      return false;
    }
    commit("LISTCANDIDATES", res);
    return true;
  },
  async getCandidate({ commit }, payload) {
    const res = await getCandidate(payload);
    if (res.error) {
      return false;
    }
    commit("GETCANDIDATE", res);
    return true;
  },
  async getProcessCandidate({ commit }, payload) {
    const res = await getProcessCandidate(payload);
    if (res.error) {
      return false;
    }
    const { data, data_structure } = res;

    commit("GETCANDIDATE", data);
    commit("GETDATASTRUCTURE", data_structure);
    return true;
  },  
  async listRHR({ commit }) {
    const res = await listRHR();
    if (res.error) {
      return false;
    }
    commit("LISTRHR", res);
    return true;
  },
  async updateStatus({ commit }, payload) {
    const res = await updateStatus(payload.body);
    if (res.error) {      
      const dateError = res.error.response.data;
      notification["error"]({
        message: dateError.error.message,
      });
      return false;
    }
    commit("RESPONSE", res);
    return true;
  },
  async getStatus({ commit }, payload) {
    const res = await getStatus(payload.body);
    if (res.error) {      
      const dateError = res.error.response.data;
      notification["error"]({
        message: dateError.error.message,
      });
      return false;
    }
    commit("RESPONSE_PROCESS", res);
    return true;
  },
  async addCandidate({ commit }, payload) {
    const res = await addCandidate(payload);
    if (res.error) {
      return res.error;
    }
    commit("RESPONSE", res);
    return true;
  },
  async addCalendar({ commit }, payload) {
    const res = await addCalendar(payload);
    if (res.error) {
      return false;
    }
    commit("RESPONSE", res);
    return true;
  },
  async deleteCandidate({ commit }, payload) {
    const res = await deleteCandidate(payload);
    if (res?.error) {
      return false;
    }
    commit("RESPONSE", res);
    return true;
  },
  async addComment({ commit }, payload) {
    const res = await addComment(payload.params, payload.body);
    if (res.error) {
      return false;
    }
    commit("RESPONSE", res);
    return true;
  },
  async updateOnBoard({ commit }, payload) {
    const res = await updateOnBoard(payload.params, payload.body);
    if (res.error) {
      return false;
    }
    commit("RESPONSE", res);
    return true;
  },
  async assignOwn({ commit }, payload) {
    const res = await assignOwn(payload.params,payload.body);
    console.log(payload);
    if (res.error) {
      return false;
    }
    commit("RESPONSE", res);
    return true;
  },
  async updateCandidate({ commit }, payload) {
    const res = await updateCandidate(payload);
    if (res.error) {
        return res.error;
    }
    commit("RESPONSE", res);
    return true;
  },
  async addApproachHistory({ commit }, payload) {
    const res = await addApproachHistory(payload);
    if (res.error) {
        return res.error;
    }
    commit("RESPONSE", res);
    return true;
  },
  async getCandidateAproachHistory({ commit }, payload) {
    const res = await getCandidateAproachHistory(payload);
    
    if (res.error) {
        return res.error;
    }
    commit("APPROACH_HISTORY", res);
    return true;
  },
  async getCommunicationsBySource({ commit }, payload) {
    const res = await getCommunicationsBySource(payload);
    if (res.error) {
      return false;
    }
    commit("COMMUNICATIONS", res);
    return true;
  },
  async getEditHistories({ commit }, payload) {
    const res = await getEditHistories(payload);
    if (res.error) {
      return false;
    }
    commit("EDIT_HISTORIES", res);
    return true;
  },
  async getEditCandidate({ commit }, payload) {
    const res = await getEditCandidate(payload);
    if (res.error) {
      return false;
    }
    commit("GETEDITCANDIDATE", res);
    return true;
  },

  async sharePublic({ commit }, payload) {
    const res = await sharePublic(payload);
    if (res.error) {
      return false;
    }
    commit("SHARE_PUBLIC", res);
    return true;
  },

  async authPublicShare({ commit }, payload) {
    const res = await authSharePublic(payload);
    if (res.error) {
      return false;
    }
    commit("AUTH_SHARE_PUBLIC", res);
    return true;
  },

  async checkAuthPublic({ commit }, payload) {
    const res = await checkAuthPublic(payload);
    console.log(res);
    
    if (res.error) {
      return false;
    }
    commit("CHECK_AUTH_PUBLIC", res);
    return true;
  },

  async getCandidatePublic({ commit }, params) {
    const res = await getCandidatePublic(params.id, params.token);
    if (res.error) {
      return false;
    }
    commit("GETCANDIDATE", res);
    return true;
  },
};
