import { getCandidatesDashBoard, getJobsDashBoard, getTasksDashBoard } from "@/apis/dashboard";

export const state = {
    candidates_dashboard: [],
    process_status: [],
    process_display: [],
    process_all: [],
    jobs_dashboard: [],
    candidates_filter: [],
    tasks: {}
};

export const getters = {
  candidates_dashboard: (state) => state.candidates_dashboard,
  process_status: (state) => state.process_status,
  process_display: (state) => state.process_display,
  process_all: (state) => state.process_all,
  jobs_dashboard: (state) => state.jobs_dashboard,
  candidates_filter: (state) => state.candidates_filter,
  tasks: (state) => state.tasks,
};

export const mutations = {
  CANDIDATES_DASHBOARD(state, data) {
    state.process_status = data.process_status;    
    state.candidates_dashboard = data.process_candidates;
    state.process_display = data.process_display;
  },
  JOBS_DASHBOARD(state, data) {
    state.process_all = data.jobs_process;    
    state.jobs_dashboard = data.jobs;
  },
  CANDIDATES_FILTER(state, data) {
    state.candidates_filter = data.process_candidates;    
  },
  TASKS_DASHBOARD(state, data) {
    state.tasks = data;    
  },
}

export const actions = {
  async getCandidatesDashBoard({ commit }, payload) {
    const res = await getCandidatesDashBoard(payload);
    
    if (res.error) {
      return false;
    }
    if (payload) {
      commit("CANDIDATES_FILTER", res);
    } else {
      commit("CANDIDATES_DASHBOARD", res);
    }
    return true;
  },

  async getJobsDashBoard({ commit }, payload) {
    const res = await getJobsDashBoard(payload);
    
    if (res.error) {
      return false;
    }
    commit("JOBS_DASHBOARD", res);
    return true;
  },

  async getTasksDashBoard({ commit }, payload) {
    const res = await getTasksDashBoard(payload);
    
    if (res.error) {
      return false;
    }
    commit("TASKS_DASHBOARD", res);
    return true;
  }
}