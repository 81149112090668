import axios from '@/plugins/axios';

const LISTJOBS = '/manager/jobs';
const DIRECTORJOBS = '/director/jobs';
const LISTCANDIDATES = '/candidates';
const DASHBOARD = 'manager/dashboard'
export async function listJobs(params) {
    try {
      const { data } = await axios.get(`${LISTJOBS}?${params}`);
      
      return data;
    } catch (error) {
      return { error };
    }
  }
export async function listJobDirectors(params) {
    try {
      const { data } = await axios.get(`${DIRECTORJOBS}?${params}`);
      
      return data;
    } catch (error) {
      return { error };
    }
  }

  export async function addJob(payload) {
    try {
      const { data } = await axios.post(`${LISTJOBS}`,payload);
  
      return data;
    } catch (error) {
      return { error };
    }
  }

export async function listCandidates(params) {
  try {
    const { data } = await axios.get(`${LISTCANDIDATES}?${params}`);

    return data;
  } catch (error) {
    return { error };
  }
}


  export async function getJob(payload) {
    try {
      const { data } = await axios.get(`${LISTJOBS}/${payload}`,);
  
      return data;
    } catch (error) {
      return { error };
    }
  }

  export async function deleteJob(payload) {
    try {
      const { data } = await axios.delete(`${LISTJOBS}/${payload}`,);
  
      return data;
    } catch (error) {
      return { error };
    }
  }

  export async function getDashBoard() {
    try {
      const { data } = await axios.get(`${DASHBOARD}`,);
  
      return data;
    } catch (error) {
      return { error };
    }
  }

export async function editJob(payload) {
  try {
    const { data } = await axios.put(`${LISTJOBS}/${payload.params.id}`, payload.body);

    return data;
  } catch (error) {
    return { error };
  }
}