import { listJobs, addJob, listCandidates ,getJob , deleteJob, getDashBoard, editJob, listJobDirectors} from "@/apis/manager";

export const state = {
  list_jobs: {},
  add_job: {},
  edit_job: {},
  job: {},
  res: {},
  list_candidates: {},
  dashboard: {},
};

export const getters = {
  list_jobs: (state) => state.list_jobs,
  add_job: (state) => state.add_job,
  edit_job: (state) => state.edit_job,
  job: (state) => state.job,
  res: (state) => state.res,
  list_candidates: (state) => state.list_candidates,
  dashboard: (state) => state.dashboard,
};

export const mutations = {
  LISTJOBS(state, data) {
    state.list_jobs = data;
  },
  ADDJOB(state, data) {
    state.add_job = data;
  },
  GETJOB(state, data) {
    state.job = data;
  },
  GETRES(state, data) {
    state.res = data;
  },
  LISTCANDIDATES(state, data) {
    state.list_candidates = data
  },
  GETDASHBOARD(state, data) {
    state.dashboard = data
  },
  EDITJOB(state, data) {
    state.edit_job = data;
  }
};

export const actions = {
  async listJobs({ commit }, params) {
    const res = await listJobs(params);
    if (res.error) {
      return false;
    }
    commit("LISTJOBS", res);
    return true;
  },
  async listJobDirectors({ commit }, params) {
    const res = await listJobDirectors(params);
    if (res.error) {
      return false;
    }
    commit("LISTJOBS", res);
    return true;
  },
  async addJob({ commit }, payload) {
    const res = await addJob(payload);
    if (res.error) {
      return false;
    }
    commit("ADDJOB", res);
    return true;
  },
  async listCandidates({ commit }, params) {
    const res = await listCandidates(params);
    if (res.error) {
      return false;
    }
    commit("LISTCANDIDATES", res);
    return true;
  },
  async getJob({ commit }, payload) {
    const res = await getJob(payload);
    if (res.error) {
      return false;
    }
    commit("GETJOB", res);
    return true;
  },
  async deleteJob({ commit },payload) {
    const res = await deleteJob(payload);
    if (res.error) {
      return false;
    }
    commit("GETRES", res);
    return true;
  },
  async getDashBoard({ commit }) {
    const res = await getDashBoard();
    if (res.error) {
      return false;
    }
    commit("GETDASHBOARD", res);
    return true;
  },
  async editJob({ commit }, payload) {
    const res = await editJob(payload);
    if (res.error) {
      return false;
    }
    commit("EDITJOB", res);
    return true;
  },
};
