import {
    getReportData,
    getJobStatus
} from "@/apis/report";

export const state = {
    reportData: {},
    jobStatusData: {},
};

export const getters = {
    reportData: (state) => state.reportData,
    jobStatusData: (state) => state.jobStatusData
};

export const mutations = {
    GETREPORTDATA(state, data) {
        state.reportData = data
    },

    GETJOBSTATUS(state, data) {
        state.jobStatusData = data
    }
};

export const actions = {
    async getReportData({ commit }, params) {
        const res = await getReportData(params);

        if (res.error) {
            return false;
        }
        commit("GETREPORTDATA", res);
        return true;
    },

    async getJobStatus({ commit }, params) {
        const res = await getJobStatus(params);
        if (res.error) {
            return false;
        }
        commit("GETJOBSTATUS", res);
        return true;
    }
};
