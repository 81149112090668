function views(path) {
  return () => import(`@/views/${path}`).then((m) => m.default || m);
}

const routes = [
  {
    path: '/',
    component: () => import('../layouts/DefaultLayout.vue'),
    meta: {
      middleware: true
    },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: views('DashBoard.vue'),
        meta: {
          title: 'DashBoard',
        },
      },
      {
        path: '/jobs',
        name: 'jobs',
        component: views('JobManagement.vue'),
        meta: {
          title: 'Jobs',
        }
      },
      {
        path: '/job-manager',
        name: 'job-manager',
        component: views('JobManager.vue'),
        meta: {
          title: 'Job-manager',
        },
      },
      {
        path: '/job-detail/:id',
        name: 'job-detail',
        component: views('JobDetail.vue'),
        meta: {
          title: 'job-detail',
        },
      },
      {
        path: '/add-job',
        name: 'add-job',
        component: views('AddJob.vue'),
        meta: {
          title: 'add-job',
        },
      },
      {
        path: '/edit-job/:id',
        name: 'edit-job',
        component: views('EditJob.vue'),
        meta: {
          title: 'edit-job',
        },
      },
      {
        path: '/users',
        name: 'user',
        component: views('UserManagement.vue'),
        meta: {
          title: 'User',
        },
        // children: [
        //   {
        //     path:':id',
        //     name: 'user',
        //     component: views('UserManagement.vue'),
        //     meta: {
        //       title: 'User',
        //     },
        //   },
        // ]
      },
      {
        path: '/user-detail/:id',
        name: 'user-detail',
        component: views('UserDetail.vue'),
        meta: {
          title: 'User Detail',
        },
      },
      {
        path: '/user-edit/:id',
        name: 'user-edit',
        component: views('UserEdit.vue'),
        meta: {
          title: 'User Edit',
        },
      },
      {
        path: '/user-creation',
        name: 'user-creation',
        component: views('UserCreation.vue'),
        meta: {
          title: 'User Creation',
        },
      },
      {
        path: '/interview',
        name: 'interview',
        component: views('InterviewManagement.vue'),
        meta: {
          title: 'Interview',
        },
      },
      {
        path: '/report',
        name: 'report',
        component: views('ReportView.vue'),
        meta: {
          title: 'Report',
        },
      },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: views('LoginView.vue'),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/page-404',
    name: 'page-404',
    component: views('Error/PageView404.vue'),
    meta: {
      title: 'Error Page',
      middleware: true
    },
  },
  {
    path: '/user-detail-share',
    name: 'user-detail-share',
    component: views('LoginShowPublic.vue'),
    meta: {
      title: 'User Detail',
    },
  },
];

export default routes;