import axios from '@/plugins/axios';

const CANDIDATES_DASHBOARD = '/dashboard/candidates';
const JOBS_DASHBOARD = '/dashboard/jobs';
const TASKS_DASHBOARD = '/dashboard/task';

export async function getCandidatesDashBoard(params) {
  try {
    const { data } = await axios.get(`${CANDIDATES_DASHBOARD}`, { params });
    return data;
  } catch (error) {
    return { error };
  }
}

export async function getJobsDashBoard() {
  try {
    const { data } = await axios.get(`${JOBS_DASHBOARD}`);
    return data;
  } catch (error) {
    return { error };
  }
}

export async function getTasksDashBoard(payload) {  
  try {
    const { data } = await axios.get(`${TASKS_DASHBOARD}?${payload?.body}`);    
    return data;
  } catch (error) {
    return { error };
  }
}