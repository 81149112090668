import axios from "@/plugins/axios";

const LISTJOBS = "/job";
const JOBTASK = "/task/my-task";
const UPDATETASK = '/task/update';
const DELETETASK = '/task/delete';
const JOBPROCESS = "/job-process/list";
const GETLISTCANDIDATE = "/job-process/list-candidate";
const ADDCANDIDATEFORJOB = "/job-process/insert-candidate";
const CHANGEPROCESS = "/job-process/submit/process";

export async function listJobs(params) {
  try {
    const { data } = await axios.get(`${LISTJOBS}?${params}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function updateStatus(params, body) {
  try {
    const { data } = await axios.post(`${LISTJOBS}/${params}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function deleteTask(params, body) {
  try {
    const { data } = await axios.post(`${DELETETASK}/${params}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function updateTask(params, body) {
  try {
    const { data } = await axios.post(`${UPDATETASK}/${params}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function changeProcess(params, body) {
  try {
    const { data } = await axios.post(`${CHANGEPROCESS}`, body);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function removeJobCandidates(job_id, candidate_id) {
  try {
    const { data } = await axios.delete(`${LISTJOBS}/${job_id}/candidates`, candidate_id);
    return data;
  } catch (error) {
    return { error };
  }
}

export async function getListCandidate(params) {    
  try {
    if(!params?.keyword) {
      const { data } = await axios.get(`${GETLISTCANDIDATE}/${params?.job_id}?page=${params?.page}&limit=${params?.limit}`);
      return data;
    } else {
      const { data } = await axios.get(`${GETLISTCANDIDATE}/${params?.job_id}?keyword=${params?.keyword}&page=${params?.page}&limit=${params?.limit}`);
      return data;
    }
  } catch (error) {
    return { error };
  }
}

// Process in job
export async function listJobProcess(job_id) {
  try {
    const { data } = await axios.get(`${JOBPROCESS}/${job_id}`);
    return data;
  } catch (error) {
    return { error };
  }
}

export async function filterJobProcess(job_id, body) {
  try {
    const { data } = await axios.get(`${JOBPROCESS}/${job_id}`, { 
      params: {
        filter: body
      }
    });
    return data;
  } catch (error) {
    return { error };
  }
}

export async function addCandidateForJob(param, payload) {
  try {
    const { data } = await axios.post(`${ADDCANDIDATEFORJOB}`, payload);
    return data;
  } catch (error) {
    return { error };
  }
}
// Task in job
export async function getListTask(param, payload) {
  try {
    const { data } = await axios.get(`${JOBTASK}/${param?.params}?${param?.body ?? ''}`, payload);
    return data;
  } catch (error) {
    return { error };
  }
}

