import { getAccessToken, getPublicToken } from '@/utils/token';
import store from '@/store';

export default async function auth({
  to,
  next,
}) {
  const token = getAccessToken();
  const publicToken = getPublicToken();

  if (publicToken && to.name === 'user-detail') {
    const isCandidatePublic = await store.dispatch('candidate/checkAuthPublic', {
      token: publicToken,
      candidate_id: to.params.id
    });
    if (isCandidatePublic) {
      next();
    } else {
      next({ name: 'Login'});
    }
  } else if (token && to.name !== 'Login') {
    await store.dispatch('auth/fetchUser');
    next();
  } else if (!token && to.name === 'user-detail-share') {
    next();
  } else { 
    next({ name: 'Login'});
  }
}
