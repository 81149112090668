import { addJob, deleteJob, editJob, getEditJob, overviewJob, updateJob } from "@/apis/job";

export const state = {
  response_post: {},
  edit_job: {},
  res: {},
  job: {},
  job_edit: {}
};

export const getters = {
  response_post: (state) => state.response_post,
  edit_job: (state) => state.edit_job,
  res: (state) => state.res,
  overviewJob: (state) => state.job,
  job_edit: (state) => state.job_edit,
};

export const mutations = {
  RESPONSE(state, data) {
    state.response_post = data
  },
  EDITJOB(state, data) {
    state.edit_job = data;
  },
  GETRES(state, data) {
    state.res = data;
  },
  OVERVIEWJOB(state, data) {
    state.job = data;
  },
  GETJOBEDIT(state, data) {
    state.job_edit = data;
  }
}

export const actions = {
  async addJob({ commit }, payload) {
    const res = await addJob(payload);
    if (res.error) {
      return res.error;
    }
    commit("RESPONSE", res);
    return true;
  },
  async editJob({ commit }, payload) {
    const res = await editJob(payload);
    if (res.error) {
      return false;
    }
    commit("EDITJOB", res);
    return true;
  },
  async overviewJob({ commit }, payload) {
    const res = await overviewJob(payload);
    if (res.error) {
      return false;
    }
    commit("OVERVIEWJOB", res);
    return true;
  },
  async deleteJob({ commit }, payload) {
    const res = await deleteJob(payload);
    if (res.error) {
      return false;
    }
    commit("GETRES", res);
    return true;
  },
  async getEditJob({ commit }, payload) {
    const res = await getEditJob(payload);
    if (res.error) {
      return false;
    }
    commit("GETJOBEDIT", res);
    return true;
  },
  async updateJob({ commit }, payload) {
    const res = await updateJob(payload);
    if (res.error) {
        return res.error;
    }
    commit("RESPONSE", res);
    return true;
  },
}