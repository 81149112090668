import { createApp } from "vue";
import App from "./App.vue";
import dayjs from "@/plugins/dayjs";
import i18n from "@/plugins/i18n";
import router from "./router";
import store from "./store";
import Cookie from "js-cookie";
import "./assets/style/style.scss";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
import vuelidate from "@/plugins/vuelidate";
import clickOutside from "@/directives/click-outside";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import "v-calendar/dist/style.css";
import { SetupCalendar, Calendar } from "v-calendar";
import {
  BootstrapVue3,
  BPopover,
  BPagination,
  BCarousel,
  BDropdown,
  BFormCheckbox,
} from "bootstrap-vue-3";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import DatePicker from "vue-datepicker-next";
import { createVuetify } from "vuetify";
import { aliases, md } from 'vuetify/iconsets/md'
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "vue-datepicker-next/index.css";

const app = createApp(App);
app.directive("click-outside", clickOutside);
app.config.globalProperties.$dayjs = dayjs;
app.config.globalProperties.$cookie = Cookie;
const vuetify = createVuetify({
  icons: {
    defaultSet: "md",
    aliases,
    sets: {
      md,
    },
  },
  components,
  directives,
});

app
  .use(i18n)
  .use(router)
  .use(store)
  .use(vuelidate)
  .use(Antd)
  .use(CKEditor)
  .use(VueLoading)
  .use(vuetify)
  .use(SetupCalendar, {})
  .use(BootstrapVue3)
  .use(VueSweetalert2)
  .use(VueAwesomeSwiper)
  .mount("#app");

app.component("VCalendar", Calendar);
app.component("b-popover", BPopover);
app.component("b-pagination", BPagination);
app.component("b-carousel", BCarousel);
app.component("b-dropdown", BDropdown);
app.component("b-form-checkbox", BFormCheckbox);
app.component("v-datepicker", Datepicker);
app.component("date-picker", DatePicker);
app.component("swiper-slide", VueAwesomeSwiper);
