import axios from '@/plugins/axios';

const GETREPORTDATA = '/rhr/jobs/statistical';
const GETJOBSTATUS = '/rhr/jobs/status';

export async function getReportData(params) {
    try {
        const { data } = await axios.get(`${GETREPORTDATA}`, {params:params});
        return data;
    } catch (error) {
        return { error };
    }
}

export async function getJobStatus(params) {
    try {
        const { data } = await axios.get(`${GETJOBSTATUS}`, {params:params});
        return data;
    } catch (error) {
        return { error };
    }
}