import { 
  getCompanies,
  getBlocks,
  getGroups,
  getLevels,
  getSkills,
  getPositions,
  getJobDetail,
  getJobDoing,
  getMeetings,
  getJobListSearch,
  getlanguagesWithCertificates,
  getCertificates,
  getEducations,
  getSources,
  getAssignUsers,
  getCommunications,
  getContractTypes,
  getWorkForms,
  getContractTimes,
  getClassifies,
  getProcessStatus,
  getTaskDataFilter
} from "@/apis/common";

export const state = {
  companies: [],
  blocks: [],
  groups: [],
  levels: [],
  skills: [],
  positions: [],
  jobs: [],
  jobListSearch: [],
  meetings: [],
  jobDetail: {},
  sources: [],
  communications: [],
  languagesWithCertificates: [],
  certificates: [],
  educations: [],
  assignUsers: [],
  contractTypes: [],
  workForms: [],
  contractTimes: [],
  classifies: [],
  processStatus: [],
  stepStatus: [],
};

// getters
export const getters = {
  companies: (state) => state.companies,
  blocks: (state) => state.blocks,
  groups: (state) => state.groups,
  levels: (state) => state.levels,
  skills: (state) => state.skills,
  positions: (state) => state.positions,
  jobs: (state) => state.jobs,
  jobListSearch: (state) => state.jobListSearch,
  meetings: (state) => state.meetings,
  jobDetail: (state) => state.jobDetail,
  sources: (state) => state.sources,
  languagesWithCertificates: (state) => state.languagesWithCertificates,
  certificates: (state) => state.certificates,
  educations: (state) => state.educations,
  assignUsers: (state) => state.assignUsers,
  taskFilter: (state) => state.taskFilter,
  communications: (state) => state.communications,
  contractTypes: (state) => state.contractTypes,
  workForms: (state) => state.workForms,
  contractTimes: (state) => state.contractTimes,
  classifies: (state) => state.classifies,
  processStatus: (state) => state.processStatus,
  stepStatus: (state) => state.stepStatus,
};

// mutations
export const mutations = {
  COMPANIES(state, data) {
    state.companies = data.map(item => {
      return{ 
        ...item,
        id: item.company_id,
        name: item.company_name,
      }
    });
  },
  BLOCKS(state, data) {
    state.blocks = data;
  },
  PROCESSSTATUS(state, data) {
    state.processStatus = data;
  },
  STEPSTATUS(state, data) {
    state.stepStatus = data;
  },
  GROUPS(state, data) {
    state.groups = data.map(item => {
      return{ 
        ...item,
        id: item.group_id,
        name: item.group_name,
      }
    });
  },
  LEVELS(state, data){
    state.levels = data.map(item => {
      return{ 
        ...item,
        id: item.level_id,
        name: item.level_name,
      }
    })
  },
  SKILLS(state, data){
    state.skills = data.map(item => {
      return{ 
        ...item,
        id: item.skill_id,
        name: item.skill_name,
      }
    })
  },
  POSITIONS(state, data){
    state.positions = data.map(item => {
      return{ 
        ...item,
        id: item.position_id,
        name: item.position_name,
      }
    })
  },
  JOBS(state, data){
    state.jobs = data.map(item => {
      return{ 
        ...item,
        id: item.job_id,
      }
    })
  },
  JOBSEARCH(state, data){
    state.jobListSearch = data.map(item => {
      return{ 
        ...item,
        id: item.job_id,
      }
    })
  },
  MEETINGS(state, data){
    state.meetings = data.map(item => {
      return{ 
        ...item,
        id: item.meeting_id,
        name: item.meeting_name,
      }
    })
  },
  GETJOBDETAIL(state, data) {
    state.jobDetail = data;
  },
  LANGUAGEWITHCERTIFICATES(state, data) {
    state.languagesWithCertificates = data;

    state.languagesWithCertificates = data.map(item => {
      item.language_certificates = item.language_certificates.map(cert => {
        return{ 
          ...cert,
          id: cert.language_certificate_id,
          name: cert.language_certificate_name,
        }
      })
      return item;
    })
  },
  CERTIFICATES(state, data){
    state.certificates = data.map(item => {
      return{ 
        ...item,
        id: item.certificate_id,
        name: item.abbreviated_certificate_name,
      }
    })
  },
  EDUCATIONS(state, data){
    state.educations = data.map(item => {
      return{ 
        ...item,
        id: item.education_id,
        name: item.education_name,
      }
    })
  },
  SOURCES(state, data){
    state.sources = data.map(item => {
      return{ 
        ...item,
        id: item.source_id,
        name: item.source_name,
      }
    })
  },
  ASSIGNUSERS(state, data){
    state.assignUsers = data.map(item => {
      return{ 
        ...item,
        id: item.user_id,
        name: item.user_name,
      }
    })
  },
  TASKDATA(state, data){
    state.taskFilter = data;
  },
  COMMUNICATIONS(state, data){
    state.communications = data.map(item => {
      return{ 
        ...item,
        id: item.communication_id,
        name: item.communication_name,
      }
    })
  },
  RHR(state, data) {
    state.rhr = data;
  },
  CONTRACTTYPES(state, data){
    state.contractTypes = data;
  },
  WORKFORMS(state, data){
    state.workForms = data;
  },
  CONTRACTTIMES(state, data){
    state.contractTimes = data;
  },
  CLASSIFY(state, data){
    state.classifies = data;
  },
};

// actions
export const actions = {
  async getCompanies({ commit }) {
    const res = await getCompanies();
    if (res.error) {
      return false;
    }
    commit("COMPANIES", res);
    return true;
  },
  async getBlocks({ commit }) {
    const res = await getBlocks();
    if (res.error) {
      return false;
    }
    commit("BLOCKS", res);
    return true;
  },
  async getProcessStatus({ commit }, payload) {
    const res = await getProcessStatus(payload);
    if (res.error) {
      return false;
    }
    commit("PROCESSSTATUS", res);
    return true;
  },
  async getProcessStepStatus({ commit }, payload) {
    const res = await getProcessStatus(payload);
    if (res.error) {
      return false;
    }
    commit("STEPSTATUS", res);
    return true;
  },
  async getGroups({ commit }) {
    const res = await getGroups();
    if (res.error) {
      return false;
    }
    commit("GROUPS", res);
    return true;
  },
  async getLevels({ commit }) {
    const res = await getLevels();
    if (res.error) {
      return false;
    }
    commit("LEVELS", res);
    return true;
  },
  async getSkills({ commit }) {
    const res = await getSkills();
    if (res.error) {
      return false;
    }
    commit("SKILLS", res);
    return true;
  },
  async getPositions({ commit }) {
    const res = await getPositions();
    if (res.error) {
      return false;
    }
    commit("POSITIONS", res);
    return true;
  },
  async getJobDoing({ commit }) {
    const res = await getJobDoing();
    if (res.error) {
      return false;
    }
    commit("JOBS", res);
    return true;
  },
  async getJobListSearch({ commit }) {
    const res = await getJobListSearch();
    if (res.error) {
      return false;
    }
    commit("JOBSEARCH", res);
    return true;
  },
  async getJobDetail({ commit }, payload) {
    const res = await getJobDetail(payload);
    if (res.error) {
      return false;
    }
    commit("GETJOBDETAIL", res);
    return true;
  },
  async getMeetings({ commit }) {
    const res = await getMeetings();
    if (res.error) {
      return false;
    }
    commit("MEETINGS", res);
    return true;
  },

  async getlanguagesWithCertificates({ commit }) {
    const res = await getlanguagesWithCertificates();
    if (res.error) {
      return false;
    }
    commit("LANGUAGEWITHCERTIFICATES", res);
    return true;
  },

  async getCertificates({ commit }) {
    const res = await getCertificates();
    if (res.error) {
      return false;
    }
    commit("CERTIFICATES", res);
    return true;
  },

  async getEducations({ commit }) {
    const res = await getEducations();
    if (res.error) {
      return false;
    }
    commit("EDUCATIONS", res);
    return true;
  },

  async getSources({ commit }) {
    const res = await getSources();
    if (res.error) {
      return false;
    }
    commit("SOURCES", res);
    return true;
  },

  async getAssignUsers({ commit }) {
    const res = await getAssignUsers();
    if (res.error) {
      return false;
    }
    commit("ASSIGNUSERS", res);
    return true;
  },

  async getTaskDataFilter({ commit }) {
    const res = await getTaskDataFilter();
    if (res?.error) {
      return false;
    }
    commit("TASKDATA", res);
    return true;
  },

  async getCommunications({ commit }, payload) {
    const res = await getCommunications(payload);
    if (res.error) {
      return false;
    }
    commit("COMMUNICATIONS", res);
    return true;
  },

  async getContractTypes({ commit }, payload) {
    const res = await getContractTypes(payload);
    if (res.error) {
      return false;
    }
    commit("CONTRACTTYPES", res);
    return true;
  },

  async getWorkForms({ commit }, payload) {
    const res = await getWorkForms(payload);
    if (res.error) {
      return false;
    }
    commit("WORKFORMS", res);
    return true;
  },

  async getContractTimes({ commit }, payload) {
    const res = await getContractTimes(payload);
    if (res.error) {
      return false;
    }
    commit("CONTRACTTIMES", res);
    return true;
  },

  async getClassifies({ commit }, payload) {
    const res = await getClassifies(payload);
    if (res.error) {
      return false;
    }
    commit("CLASSIFY", res);
    return true;
  },
};