import axios from "@/plugins/axios";

const COMPANY = "/companies";
const BLOCKS = "/blocks";
const GROUPS = "/groups";
const LEVELS = "/levels";
const SKILLS = "/skills";
const POSITIONS = "/positions";
const JOBDETAIL = "/job";
const JOBLISTSEARCH = "/recent/job";
const MEETINGS = "/meetings";
const SOURCES = "/sources";
const COMMUNICATIONS = "/communications";
const LANGUAGEWITHCERTIFICATES = "/language-certifications";
const CERTIFICATES = "/certificates";
const EDUCATIONS = "/educations";
const ASSIGNUSERS = "/rhr-users";
const TASKDATA = "/task/data-filter";
const CONTRACTTYPES = "/contract-type";
const WORKFORMS = "/form-of-work";
const CONTRACTTIMES = "/contract-time";
const CLASSIFY = "/classify";
const PROCESSSTATUS = "/process-status";

export async function getCompanies() {
  try {
    const { data } = await axios.get(`${COMPANY}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getLevels() {
  try {
    const { data } = await axios.get(`${LEVELS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getBlocks() {
  try {
    const { data } = await axios.get(`${BLOCKS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getProcessStatus(payload) {  
  try {
    const { data } = await axios.get(`${PROCESSSTATUS}?${payload?.params ?? ''}`);

    return data;
  } catch (error) {
    return { error };
  }
}


export async function getGroups() {
  try {
    const { data } = await axios.get(`${GROUPS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getSkills() {
  try {
    const { data } = await axios.get(`${SKILLS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getPositions() {
  try {
    const { data } = await axios.get(`${POSITIONS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getJobDetail(payload) {
  try {
    const { data } = await axios.get(`${JOBDETAIL}/${payload}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getJobDoing() {
  try {
    const { data } = await axios.get(`${JOBDETAIL}`);

    return data;
  } catch (error) {
    return { error };
  }
}
export async function getJobListSearch() {
  try {
    const { data } = await axios.get(`${JOBLISTSEARCH}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getMeetings() {
  try {
    const { data } = await axios.get(`${MEETINGS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getlanguagesWithCertificates() {
  try {
    const { data } = await axios.get(`${LANGUAGEWITHCERTIFICATES}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getCertificates() {
  try {
    const { data } = await axios.get(`${CERTIFICATES}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getEducations() {
  try {
    const { data } = await axios.get(`${EDUCATIONS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getSources() {
  try {
    const { data } = await axios.get(`${SOURCES}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getCommunications() {
  try {
    const { data } = await axios.get(`${COMMUNICATIONS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getAssignUsers() {
  try {
    const { data } = await axios.get(`${ASSIGNUSERS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getTaskDataFilter() {
  try {
    const res = await axios.get(`${TASKDATA}`);

    return res;
  } catch (error) {
    return { error };
  }
}

export async function getContractTypes() {
  try {
    const { data } = await axios.get(`${CONTRACTTYPES}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getWorkForms() {
  try {
    const { data } = await axios.get(`${WORKFORMS}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getContractTimes() {
  try {
    const { data } = await axios.get(`${CONTRACTTIMES}`);

    return data;
  } catch (error) {
    return { error };
  }
}

export async function getClassifies() {
  try {
    const { data } = await axios.get(`${CLASSIFY}`);

    return data;
  } catch (error) {
    return { error };
  }
}