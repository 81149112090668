import axios from '@/plugins/axios';

const GETINTERVIEWSCHEDULE = '/get-interview-schedule';
const EDITINTERVIEWSCHEDULE = '/rhr/calendars/update';
const ADDINTERVIEWSCHEDULE = '/rhr/calendars/add';
const DELETEINTERVIEWSCHEDULE = '/rhr/calendars';

export async function getInterviewSchedule(params) {
    try {
        const { data } = await axios.get(`${GETINTERVIEWSCHEDULE}`, {params:params});
        return data;
    } catch (error) {
        return { error };
    }
}

export async function updateInterviewSchedule(payload) {
    try {
        const { data } = await axios.post(`${EDITINTERVIEWSCHEDULE}`, payload);
        return data;
    } catch (error) {
        return { error };
    }
}

export async function addInterviewSchedule(payload) {
    try {
        const { data } = await axios.post(`${ADDINTERVIEWSCHEDULE}`, payload);
        return data;
    } catch (error) {
        return { error };
    }
}

export async function deleteInterviewSchedule(params) {
    try {
        const { data } = await axios.delete(`${DELETEINTERVIEWSCHEDULE}/${params}`);
        return data;
    } catch (error) {
        return { error };
    }
}
